<template>
  <button class="action" @click="submit">
    <div v-if="!isLoading">
      {{ buttonText }}
    </div>
    <div class="loading-spin" v-else>
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    buttonText: String,
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    submit() {
      if (!this.isLoading) {
        this.$emit('submit');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.action {
  display: block;
  text-align: center;
  padding: 12px 12px;
  width: 100%;
  background-color:black;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 100px;
  margin-top: 30px;
  margin-bottom: 30px;
  box-sizing: border-box;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  border-radius: 50%;
  margin-top: 2px;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
